import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {MemberPublicInfoFragment} from './GQL/gql-query.service';
import {TypesenseService} from './typesense.service';
import {AnalyticsService} from './analytics.service';
import {combineLatest, firstValueFrom} from 'rxjs';
import {IHit, IParentFacetInfo, ISearchResult, ITypesenseResult} from '../model/searchResult.model';
import {cloneDeep} from "lodash-es";
import {IProject} from "../model/project.model";
import {ICenter} from "../model/center.model";
import {PostHelper} from "../util/post.helper";
import {RoutingHelper} from "../util/routing.helper";
import {IUser} from "../model/user.model";
import {TranslateService} from "@ngx-translate/core"

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private apollo: Apollo,
    private typesenseService: TypesenseService,
    private tracker: AnalyticsService,
    private postHelper: PostHelper,
    private routingHelper: RoutingHelper,
    private trans: TranslateService
  ) {
  }

  async runSearch(input: string): Promise<ISearchResult> {


    const searchResult = await firstValueFrom(combineLatest(
      [
        this.runBackendSearch(input),
        this.typesenseService.search(input)
      ]
    ));

    const res = cloneDeep(searchResult[0]);

    let count = 0;
    Object.keys(res).forEach((key) => {
      count += res[key].preview?.length || 0;
    });

    res.content = searchResult[1];
    count += res.content.hits.length;

    res.total = count;

    this.tracker.trackSearch(input, count);
    return res;
  }

  async getFacetGroups(result: ITypesenseResult): Promise<IParentFacetInfo[]> {
    let otherParentFacetCount = result.found;

    let parentFacetInfos : IParentFacetInfo[] = [];

    const parentFacets = (result.facet_counts.find((f) => {
      return f.field_name === 'parent_facet'
    }) || {counts: []})
    .counts.filter(fCount => {
      const parts = fCount.value.split("|", 3);
      return fCount.count > 2 && parts[2];
    });

    const centerQueries = [];
    const projectQueries = [];


    for (let i = 0; i < parentFacets.length; i++) {
      const fCount = parentFacets[i];
      const parts = fCount.value.split("|", 3);

      otherParentFacetCount = otherParentFacetCount - fCount.count;

      const res : IParentFacetInfo = {
        count: fCount.count,
        parentId: parts[0],
        parentType: parts[1],
        parentName: parts[2],
        image: null,
        route: null,
      };

      // const parent = await this.postHelper.getParent(res.parentType, res.parentId)
      switch (res.parentType.toLowerCase()) {
        case 'project':
          projectQueries.push(`project${res.parentId}: project(id: ${res.parentId}) {
  ...ProjectFragment
}`);
          break;
        case 'center':
          centerQueries.push(`center${res.parentId}: center(id: "${res.parentId}") {
  ...CenterFragment
}`);
          break
      }

      parentFacetInfos[i] = res;
    }

    let centerInfos = centerQueries.length ? (await firstValueFrom(this.apollo
      .use('sso')
      .query({
        query: gql`
          query ssoQuery {
          ${centerQueries.join(' ')}
          }
          fragment CenterFragment on Center {
            id
            slug
            pictures {
              url
            }
          }
        `
      })
    ) as any).data: {};

    let projectInfos = projectQueries.length ? (await firstValueFrom(this.apollo
      .use('app')
      .query({
        query: gql`
          query appQuery {
          ${projectQueries.join(' ')}
          }
          fragment ProjectFragment on Project {
            id
            slug
            picture {
              src
              aspectRatio
              focal
            }
          }
        `
      })
    ) as any).data: {};

    parentFacetInfos = parentFacetInfos.map((res) => {
      let parent;
      const type = res.parentType.toLowerCase();
      switch (type) {
        case 'project':
          parent = projectInfos[type + res.parentId];
          res.image = (parent as IProject).picture;
          res.route = this.routingHelper.getProjectRoute(parent);
          break;
        case 'center':
          parent = centerInfos[type + res.parentId];
          res.image = (parent as ICenter).pictures?.length ? {src: parent.pictures[0].url} : null;
          res.route = this.routingHelper.getCenterRoute(parent);
          break;
      }
      return res;
    })

    if (parentFacetInfos.length && otherParentFacetCount > 0) {
      parentFacetInfos.push({
        count: otherParentFacetCount,
        parentName: 'Others',
      })
    }

    return parentFacetInfos;
  }

  centerToSearchHit (center: ICenter): IHit {
    return {
      document: {
        cover: {src: center.pictures[0]?.url || '/assets/imgs/nophoto_center.png', aspectRatio: 0},
        title: center.displayName || center.name,
        route: this.routingHelper.getCenterRoute(center)
      },
      highlight: {
        content: {
          snippet: this.trans.instant( 'country.' + center.country?.code)
        }
      }
    }
  }

  memberToSearchHit (member: IUser) : IHit {
    return {
      document: {
        cover: {src: member.userData?.picture?.url || '/assets/imgs/nophoto_member.png', aspectRatio: 0},
        title: member.givenName + ' ' + member.familyName,
        route: this.routingHelper.getMemberRoute(member)
      },
      highlight: {
        content: {
          snippet: (member.center?.displayName || member.center?.name) + ', ' + this.trans.instant('country.' + member.center?.country?.code),
        }
      }
    }
  }

  parentFacetToSearchHit(parentFacet: IParentFacetInfo, query: string) {
    return {
      document: {
        cover: parentFacet.image,
        title: parentFacet.parentName,
        // @todo - build the route to the search result
        route: '/search',
        queryParams: {
          q: query,
          ref: 'other',
          limit: parentFacet.parentType + ':' + parentFacet.parentId
        }
      },
      highlight: {
        content: {
          snippet: parentFacet.count + ' hits',
        }
      }
    }
  }

  async runBackendSearch(query: string, limit = 8, page = 1, excludes = []) {

    let body = '';

    if (excludes.indexOf('spaces') < 0 ) {
      body += `
           spaces {
            totalCount
            preview {
              href
              title
              image {
                src
                aspectRatio
                focal
              }
            }
          }
        `
    }

    if (excludes.indexOf('centers') < 0 ) {
      body += `
           centers {
            totalCount
            preview {
              id
              displayName
              slug
              name
              pictures {
                url
              }
              country {
                code
              }
            }
          }
        `
    }

    if (excludes.indexOf('countries') < 0 ) {
      body += `
           countries {
            totalCount
            preview {
              id
              name
              code
            }
          }
        `
    }

    if (excludes.indexOf('members') < 0 ) {
      body += `
           members {
            totalCount
            preview {
              ...MemberPublicInfoFragment
            }
          }
        `
    }

    const backendSearchQuery = gql`{
        search (query: "${query}", limit: ${limit}, page: ${page}) {
${body}
        }
      }
      ${ excludes.indexOf('members') < 0 ? MemberPublicInfoFragment : ''}
      `;

    const res = await firstValueFrom(this.apollo.use('app')
      .query({
        query: backendSearchQuery
      }));

    return (res.data as any).search as ISearchResult;
  }

  async runCenterSearch(query, limit = 15, includeAddress = false) {
    const result: any = await this.apollo
      .use('app')
      .query({
        query: gql`{
                  search (query: "${query}", limit: ${limit || 15}) {
                    centers {
                      totalCount
                      preview{
                        id
                        displayName
                        type
                        slug
                        name
                        pictures {
                          url
                        }
                        country {
                          id
                          code
                        }
                        latitude
                        longitude
                        ${includeAddress ? `mainAddress {
                          street_address
                          city
                          postal_code
                          region
                          country
                        }` : ''}
                        }
                      }
                    }
                  }`
      }).toPromise();
    return result?.data.search?.centers;
  }

  async runMemberSearch(input) {
    const result: any = await this.apollo
      .use('app')
      .query({
        query: gql`{
            search (query: "${input}", limit: 8) {
              members {
                totalCount
                preview {
                  ...MemberPublicInfoFragment
                }
              }
            }
          }
          ${MemberPublicInfoFragment}
          `
      }).toPromise();

    return result?.data.search?.members;
  }

  async runFollowerSearch(input: string, followedEntityId: string, followedEntityType: string, limit = 8) {
    const result: any = await this.apollo
      .use('app')
      .query({
        query: gql`{
            search (query: "${input}", limit: ${limit}, followedEntityId: "${followedEntityId}", followedEntityType: "${followedEntityType}") {
              followers {
                totalCount
                preview {
                  ...MemberPublicInfoFragment
                }
              }
            }
          }
          ${MemberPublicInfoFragment}
          `
      }).toPromise();

    return result?.data.search?.followers;
  }
}
