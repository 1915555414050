import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SearchService} from "../../core/services/search.service";
import {RoutingHelper} from "../../core/util/routing.helper";
import {BrowserService} from "../../core/services/browser.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {IFacetCount, IParentFacetInfo, ISearchResult} from "../../core/model/searchResult.model";
import {BehaviorSubject} from "rxjs";
import {ImageProxy} from "../../core/util/image-proxy";

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html'
})
export class SearchModalComponent implements AfterViewInit {

  displayResults = false;
  searchResults: ISearchResult;
  parentFacets: IParentFacetInfo[] = null
  loading = true;
  searchUpdated = new BehaviorSubject('');
  showMinLength = false;

  @ViewChild('search') searchInput: ElementRef<HTMLFormElement>;

  constructor(
    public searchService: SearchService,
    public routingHelper: RoutingHelper,
    public browserService: BrowserService,
    private trans: TranslateService,
    private router: Router,
    public imageProxy: ImageProxy,
  ) {
    this.searchUpdated.asObservable()
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged()).subscribe((query) => this.sendRequest(query));
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  sendRequest(value) {
    if (value.length > 2 || value === 'ec') {
      this.loading = true;
      this.showMinLength = false;
      this.displayResults = true;
      this.searchUpdated.next(value);

      this.searchService.runSearch(value).then(async result => {
        this.parentFacets = await this.searchService.getFacetGroups(result.content);
        this.searchResults = result;
        this.loading = false;
        this.displayResults = true;
      })
    } else if (value && value.length < 3) {
      this.showMinLength = true;
      this.searchResults = null;
      this.parentFacets = null;
      this.loading = false;
      this.displayResults = true;
    } else {
      // zero characters
      this.showMinLength = false;
      this.searchResults = null;
      this.parentFacets = null;
      this.displayResults = false;
    }
  };

  getContentHeadlineFromFacets(facetCounts: IFacetCount[]) {
    const parent = facetCounts.find((facet) => {
      return facet.field_name === 'parent_type_facet';
    })

    if (!parent) {
      return this.trans.instant('search_type.content')
    }

    const parentTypeCount = parent.counts.map((count) => {
      return count.count
    });

    if (parent.counts.length > 1) {
      const args = {
        count: parentTypeCount,
        list: parent.counts.slice(0, parent.counts.length - 1).map((count) => {
          return this.trans.instant('search_type.' + count.value)
        }).join(', '),
        last: this.trans.instant('search_type.' + parent.counts[parent.counts.length - 1].value)
      };

      return this.trans.instant('search_headline.multiple', args)
    }

    return this.trans.instant('search_headline.single', {
      count: parentTypeCount,
      item: this.trans.instant('search_type.' + parent.counts[0].value)
    });
  }

  handleEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate(['/search'], { queryParams: { q: this.searchUpdated.value } });
    }
  }

}
