import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {SearchService} from '../../core/services/search.service';
import {TypesenseService} from '../../core/services/typesense.service';
import {
  ICenterSearchResult, IHit,
  IMemberSearchResult, IParentFacetInfo,
  ITypesenseResult
} from '../../core/model/searchResult.model';
import {RoutingHelper} from '../../core/util/routing.helper';
import {IntercomService} from '../../core/services/intercom.service';
import {IAppImage} from "../../core/model/app-image.model";
import {ImageProxy} from "../../core/util/image-proxy";
import {PostHelper} from "../../core/util/post.helper";
import {TranslateService} from "@ngx-translate/core";

interface IParentConfig {
    type: string,
    id: string | number
    name?: string
}

@Component({
    selector: 'app-search-detail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search-detail.component.html',
})
export class SearchDetailComponent implements OnInit, OnDestroy, OnChanges, DWCWidgetComponent {

    id: number;
    type: string;
    data: any;
    preventUnsubscribe?: boolean;
    colSizes: object;

    showResults = false;
    lastQuery = '';

    lamaImpressionParents: IParentConfig[] = [];
    lamaLetterParents: IParentConfig[] = [];

    routeOfWidget: string;

    activeQuery = new BehaviorSubject('');
    activeFilter = new BehaviorSubject('');
    limitTo : BehaviorSubject<IParentConfig> = new BehaviorSubject(null);

    impressionResult: ITypesenseResult = null;
    lettersResult: ITypesenseResult = null;
    membersResult: ITypesenseResult = null;
    centersResult: ITypesenseResult = null;
    parentFacets: IParentFacetInfo[] = null
    otherResult: ITypesenseResult = null;

    detailResult: ITypesenseResult = null;
    detailPage = 1;

    $querySubscription: Subscription;
    $filterSubscription: Subscription;
    $intercomSubscription: Subscription;

    filters = [
        {
            label: 'All', ref: '', filterBy: '', shouldDisplay: () => {
                return true;
            }
        },
        {
            label: 'Lama Photos', ref: 'impressions', shouldDisplay: () => {
                return this.impressionResult?.found > 0;
            }
        },
        {
            label: 'Letters', ref: 'letters', shouldDisplay: () => {
                return this.lettersResult?.found > 0;
            }
        },
        {
            label: 'Centers', ref: 'centers', shouldDisplay: () => {
                return this.centersResult?.found > 0;
            }
        },
        {
            label: 'People', ref: 'people', shouldDisplay: () => {
                return this.membersResult?.found > 0;
            }
        },
        {
            label: 'Everything else', ref: 'other', shouldDisplay: () => {
                return this.otherResult?.found > 0;
            }
        }
    ]

    filtersByRef: Record<string, any> = {}

    constructor(
        private route: ActivatedRoute,
        private searchService: SearchService,
        private typesenseService: TypesenseService,
        public routingHelper: RoutingHelper,
        private intercomService: IntercomService,
        private cdr: ChangeDetectorRef,
        public imageProxy: ImageProxy,
        private postHelper: PostHelper,
        private trans: TranslateService
    ) {
    }

    async ngOnInit() {
      // first the base, the otehr functions depend on
      this.lamaImpressionParents = this.data.lamaImpressionParents ? JSON.parse(this.data.lamaImpressionParents) : [];
      this.lamaLetterParents = this.data.lamaLetterParents ? JSON.parse(this.data.lamaLetterParents) : [];

      this.routeOfWidget = this.routingHelper.getCurrentPageRoute();

      this.filters.forEach((filter) => {

          switch (filter.ref) {
              case 'impressions':
                  filter.filterBy = this.buildTypesenseFilterFromConfig(this.lamaImpressionParents);
                  break;
              case 'letters':
                  filter.filterBy = this.buildTypesenseFilterFromConfig(this.lamaLetterParents);
                  break;
              case 'other':
                  filter.filterBy = this.buildTypesenseFilterFromConfig(this.lamaImpressionParents, true) + ' && ' +
                      this.buildTypesenseFilterFromConfig(this.lamaLetterParents, true);
          }

          this.filtersByRef[filter.ref] = filter;
      })

      // this gets triggered on initial load and whenever the query params change
      const params = await firstValueFrom(this.route.queryParams);

      const limit = (params.limit || '').trim();

      if (limit) {
        const [pType, pId] = limit.split(':', 2);
        const parent = await this.postHelper.getParent(pType, pId);

        this.limitTo.next({
          type: pType, id: pId, name: this.postHelper.getParentTitle(pType, parent)
        });
      }

      const query = (params.q || '').trim();
      this.activeQuery.next(query);
      this.activeFilter.next((params.ref || '').trim());

      if (query) {
        await this.processSearch(query);
      }

      // this is triggered by the actual search input
      this.$querySubscription = this.activeQuery.asObservable()
        .pipe(debounceTime(90))
        .pipe(distinctUntilChanged())
        .subscribe(async (query) => {

          this.updateParams({ q: query, ref: this.activeFilter.value });

          await this.processSearch(query);

          }
        );

      this.$filterSubscription = this.activeFilter.asObservable().pipe(distinctUntilChanged()).subscribe(async (filterVal) => {

        const query = this.activeQuery.value;
        const params : Record<string, any> = {ref: filterVal, q: query};

        if (this.limitTo.value) {
          params.limit = this.limitTo.value.type + ':' + this.limitTo.value.id;
        }

        this.updateParams(params);

        if (filterVal && this.filtersByRef[filterVal]) {
          this.activeFilter.next(filterVal);

          this.detailPage = 1;
          this.detailResult = await this.processDetailSearch(query, filterVal);

          this.intercomService.sendMessage('resume-firing-scrolled-to-bottom-events');
        } else {
          this.detailResult = null;
        }

        if (!filterVal && query) {
          this.showResults = true;
        }

        this.cdr.detectChanges();
      })

      // this gets triggered when scrolling to the bottom of the page
      this.$intercomSubscription = this.intercomService.subject.subscribe(async message => {
          console.log(message);


          if (
              message === 'scrolled-to-the-end' &&
              this.activeFilter.value &&
              this.routeOfWidget === this.routingHelper.getCurrentPageRoute()
          ) {
              this.detailPage++;

              const nextPage = await this.processDetailSearch(this.activeQuery.value, this.activeFilter.value)

              this.detailResult.hits = this.detailResult.hits.concat(nextPage.hits);

              if (this.detailResult.found > this.detailResult.hits.length) {
                  this.intercomService.sendMessage('resume-firing-scrolled-to-bottom-events');
              }

              this.cdr.detectChanges();
          }
      });
    }

    ngOnDestroy() {
      this.$querySubscription?.unsubscribe();
      this.$filterSubscription?.unsubscribe();
      this.$intercomSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // well, do nothig really - the changes are triggered from within
    }

    updateParams(params: Record<string, any>) {

      const usp = new URLSearchParams();

      Object.keys(params).forEach(key => {
        if (params[key]) {
          usp.append(key, params[key])
        }
      })

      window.history.replaceState(null, null, window.location.origin + window.location.pathname + '?' + usp.toString());
    }

    private buildTypesenseFilterFromConfig(config: IParentConfig[], negate = false) {
        return config.map((conf) => {
            return `(parent_id:${negate ? '!' : ''}=${conf.id} ${negate ? '||' : '&&'} parent_type:${negate ? '!' : ''}=${conf.type})`;
        }).join(negate ? ' && ' : ' || ')
    }

    async processDetailSearch (query, filterVal) : Promise<ITypesenseResult> {

      switch (filterVal) {
        default:
          return await this.typesenseService.rawSearch({
            q: query,
            query_by: 'title,content',
            sort_by: 'followers:desc, created:desc',
            filter_by: this.limitTo.value ? this.buildTypesenseFilterFromConfig([this.limitTo.value]) : this.filtersByRef[filterVal].filterBy,
            limit: 30,
            page: this.detailPage
          });
        case 'centers':
          const centersRes = (await this.searchService.runBackendSearch(query, 30, this.detailPage, ['countries', 'spaces', 'members'])).centers;

          return {
            found: centersRes.totalCount,
            hits: centersRes.preview.map(this.searchService.centerToSearchHit.bind(this.searchService))
          }
        case 'people':
          const memberRes = (await this.searchService.runBackendSearch(query, 30, this.detailPage, ['countries', 'spaces', 'centers'])).members;
          return {
            found: memberRes.totalCount,
            hits: memberRes.preview.map(this.searchService.memberToSearchHit.bind(this.searchService))
          }
      }


    }

    async processSearch(query: string) {

        if (this.lastQuery !== query) {

            if (query) {

                this.lastQuery = query;
                query = query.toLocaleLowerCase();

                // this.showResults = false;

                // @todo - this might be better located in the search service
                const res = await firstValueFrom(combineLatest(
                    [
                        this.typesenseService.rawSearch({
                            q: query,
                            query_by: 'title,content',
                            sort_by: 'followers:desc, created:desc',
                            filter_by: this.filtersByRef.impressions.filterBy,
                            limit: 1
                        }),
                        this.typesenseService.rawSearch({
                            q: query,
                            query_by: 'title,content',
                            sort_by: 'followers:desc, created:desc',
                            filter_by: this.filtersByRef.letters.filterBy,
                            limit: 6
                        }),
                        this.searchService.runBackendSearch(query, 4, 1, ['spaces', 'countries']),
                        this.typesenseService.rawSearch({
                            q: query,
                            query_by: 'title,content',
                            sort_by: 'followers:desc, created:desc',
                            filter_by: this.filtersByRef.other.filterBy,
                            facet_by: 'parent_facet',
                            facet_return_parent: 'parent_id, parent_type',
                            limit: 12
                        })
                    ]
                ));

                this.impressionResult = res[0];

                this.lettersResult = res[1];

                this.membersResult = {
                  found: (res[2].members as IMemberSearchResult).totalCount,
                  hits: (res[2].members as IMemberSearchResult).preview.map(this.searchService.memberToSearchHit.bind(this.searchService))
                };

                this.centersResult = {
                  found: (res[2].centers as ICenterSearchResult).totalCount,
                  hits: (res[2].centers as ICenterSearchResult).preview.map(this.searchService.centerToSearchHit.bind(this.searchService)),
                }

                this.otherResult = res[3];
                this.parentFacets = await this.searchService.getFacetGroups(this.otherResult)
                this.showResults = true;
                this.cdr.detectChanges();
            } else {
                this.showResults = false;
            }

            this.cdr.detectChanges();
        }
    }

    parseImage(coverString?: string|IAppImage) {
        if (coverString && typeof coverString === 'string') {
            return JSON.parse(coverString as string);
        }
        return coverString || {src: '/assets/imgs/nophoto_center.png', aspectRatio: 0};
    }

    formatDate(timestamp: number) {
        const date = new Date();
        date.setTime(timestamp * 1000); // this comes from php
        return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    }

  getSublineHtml(hit: IHit) {
      return (hit.document.created ? this.formatDate(hit.document.created) : '') +
        (hit.highlight.content?.snippet ? (hit.document.created ? ': ' : '') + hit.highlight.content?.snippet : '');
  }
}
