import {Component} from '@angular/core';
import {ModalService} from "../../core/util/ui/modal.service";
import {SearchModalComponent} from "../search-modal/search-modal.component";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html'

})
export class SearchComponent {
    constructor(
        private modalService: ModalService,
    ) {
    }

  openModal() {
    this.modalService.open(SearchModalComponent, {}, {
      centered: false,
      modalDialogClass: 'modal-dialog--sidebar',
      windowClass: 'modal--sidebar',
      size: 'md'
    })
  }
}
