import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {GqlService} from '../../core/services/GQL/gql.service';
import {
  GET_PROJECT_MEMBERS_SLUG,
  ADD_MEMBER_TO_PROJECT,
  REMOVE_MEMBER_FROM_PROJECT,
  UPDATE_PROJECT_MEMBERSHIP_MUTATION
} from '../../core/services/GQL/gql-query.service'
import {SearchService} from '../../core/services/search.service';
import {Apollo} from 'apollo-angular';
import {UserService} from '../../core/services/user.service';
import {IMembership} from '../../core/model/membership.model';
import {RoutingHelper} from '../../core/util/routing.helper';
import {IProject} from '../../core/model/project.model';
import {AccessService, ENTITY_PROJECT, PERMISSION_ADMINS, PERMISSION_MEMBERS} from '../../core/services/access.service';
import {MessageService} from '../../core/services/message.service';
import {AnalyticsService, CATEGORY_MEMBER_SEARCH} from "../../core/services/analytics.service";
import {IUser} from "../../core/model/user.model";
import {firstValueFrom} from "rxjs";
import {ConfirmationPopoverComponent} from "../confirmation-popover/confirmation-popover.component";
import {ModalService} from "../../core/util/ui/modal.service";
import {StructureService} from "../../core/services/cms/structure.service";

@Component({
  selector: 'app-add-members-modal',
  templateUrl: './add-members-modal.component.html',
})
export class AddMembersModalComponent implements OnInit {

  @Input() projectName: string; // actualy the project slug...
  @Input() project?: IProject;

  @ViewChild('searchInput') searchInput;

  membersListFromSearch: Array<any> = []
  loadingMembers = true;

  projectMembers: IMembership[] = [];
  invitedProjectMembers: IMembership[] = [];

  allProjectMembers: IMembership[] = [];
  allInvitedProjectMembers: IMembership[] = [];

  loadingSearch = false;
  searchMessage = '';
  requestError = false;
  requestSent = false;
  errorMessage: any;

  hasMembersAccess = false;
  hasAdminsAccess = false;

  availableTags: string[] = [];

  user;

  constructor(
    private GQL: GqlService,
    private searchService: SearchService,
    private apollo: Apollo,
    public userProvider: UserService,
    private accessService: AccessService,
    public routingHelper: RoutingHelper,
    private messageService: MessageService,
    private readonly tracker: AnalyticsService,
    private modalService: ModalService,
    private structureService: StructureService,
  ) {
  }

  ngOnInit() {

    if (!this.projectName && this.project) {
      this.projectName = this.project.slug;
    }

    this.getProjectMembers()
    this.user = this.userProvider.user$.value;
    this.accessService.hasEntityRight(ENTITY_PROJECT, this.project.id, PERMISSION_MEMBERS).then((access) => {
      this.hasMembersAccess = access
    });
    this.accessService.hasEntityRight(ENTITY_PROJECT, this.project.id, PERMISSION_ADMINS).then((access) => this.hasAdminsAccess = access);

  }

  fillLabels(membership: IMembership) {
    if (membership.labels && membership.labels.length) {
      membership.labels.forEach(label => {
        if (this.availableTags.indexOf(label) < 0) {
          this.availableTags.push(label);
        }
      })
    }
  }

  async getProjectMembers() {
    this.GQL.runQuery(GET_PROJECT_MEMBERS_SLUG, {Slug: this.projectName}).then((result: any) => {
      this.allProjectMembers = result.project.members;
      this.projectMembers = result.project.members;
      this.invitedProjectMembers = result.project.invitedMembers;
      this.allInvitedProjectMembers = result.project.invitedMembers;

      this.availableTags = [];
      this.projectMembers.forEach(this.fillLabels.bind(this));
      this.invitedProjectMembers.forEach(this.fillLabels.bind(this));

      this.requestSent = false;
      this.loadingMembers = false;
    })
  }

  async addToProject(member, index) {
    this.errorMessage = '';
    this.requestSent = false;
    this.requestError = false;
    this.sendMemberRequest(member).then(() => {
      this.requestSent = true;
      this.membersListFromSearch.splice(index, 1)

      setTimeout(() => this.getProjectMembers(), 1000);
    }).catch(err => {
      this.requestError = true;
      if (err.graphQLErrors[0].message === 'unauthorized. you need to be _CONFIRMED_ project member') {
        this.errorMessage = 'You are unauthorized for this operation';
      } else {
        // this is the most likely cause, the error handling is not so good in the backend, need a specific response code for this error
        this.errorMessage = err.graphQLErrors[0].message;
      }
    });
  }

  confirmRemoveMember(user: IUser) {

    const modal = this.modalService.open(ConfirmationPopoverComponent, {
      text: `Do you really want to remove ${user.givenName} ${user.familyName} from the project?`,
      collectMessage: true,
      messagePlaceholder: `Optional message to ${user.givenName} ${user.familyName}`,
        confirm: async (message: string) => {
          modal.dismiss()
          this.structureService.startOverlayAjax();
          await this.removeMember(user, message);
          this.structureService.endOverlayAjax();
        },
        dismiss: () => modal.dismiss(),
      }, {size: 'sm'}
    );
  }

  async removeMember(user: IUser, message = '') {

    await firstValueFrom(this.apollo.use('app').mutate({
      mutation: REMOVE_MEMBER_FROM_PROJECT,
      variables: {input: {userId: user.id, projectId: this.project.id, message}},
      refetchQueries: [{query: GET_PROJECT_MEMBERS_SLUG, variables: {Slug: this.projectName}}]
    }));

    setTimeout(() => this.getProjectMembers(), 200);
  }

  async sendMemberRequest(member) {
    return await firstValueFrom(this.apollo.use('app').mutate({
      mutation: ADD_MEMBER_TO_PROJECT,
      variables: {input: {userId: member.id, projectId: this.project.id}},
      refetchQueries: [{query: GET_PROJECT_MEMBERS_SLUG, variables: {Slug: this.projectName}}]
    }))
  }

  filterMemberList(list: IMembership[], query: string) {
    query = query.toLocaleLowerCase();
    return list.filter((membership) => {
      const member = membership.user;
      return (member.givenName + ' ' + member.familyName).toLocaleLowerCase().indexOf(query) > -1 ||
        member.center?.name.toLocaleLowerCase().indexOf(query) > -1 ||
        membership.labels?.find((label) => label.toLocaleLowerCase().indexOf(query) > -1)
    })
  }

  onKeyUpFilterAndSearch(input) {
    if (input.length === 0) {
      this.loadingSearch = false;
      this.membersListFromSearch = [];
      this.searchMessage = '';
      this.projectMembers = this.allProjectMembers;
      this.invitedProjectMembers = this.allInvitedProjectMembers;
    } else if (input.length >= 3) {
      this.searchMessage = '';

      if (!this.project?.autoMembership?.exclusive && this.hasMembersAccess) {
        this.loadingSearch = true;

        this.searchService.runMemberSearch(input).then((value: any) => {

          this.tracker.trackSearch(input, value.preview.length, CATEGORY_MEMBER_SEARCH);

          this.membersListFromSearch = value.preview.filter((member) => {
            return !this.allProjectMembers.find((membership) => {
                return membership.user.id === member.id
              }) &&
              !this.allInvitedProjectMembers.find((membership) => {
                return membership.user.id === member.id
              })
          });
          this.loadingSearch = false;
        });
      }

      this.projectMembers = this.filterMemberList(this.allProjectMembers, input);
      this.invitedProjectMembers = this.filterMemberList(this.allInvitedProjectMembers, input);

    }
  }

  memberAccessAllowed(membership: any) {
    if (this.project?.autoMembership?.exclusive) {
      return false;
    }

    return membership.user.id === this.user.id || this.hasMembersAccess;
  }

  adminAccessAllowed(membership: any) {
    return this.hasAdminsAccess;
  }

  async changeMembershipStatus(membership: any, newStatus: number) {

    const res = await firstValueFrom(this.apollo.use('app').mutate({
      mutation: UPDATE_PROJECT_MEMBERSHIP_MUTATION,
      variables: {
        input: {
          projectId: this.project.id,
          userId: membership.user.id,
          status: newStatus
        }
      }
    })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    const update = (res as any)?.data?.projectUpdateMembership;

    membership.labels = update.labels;
    membership.status = update.status;
  }
}
