<app-modal-frame [title]="'Search all of dw-connect'">

  <div slot="header" class="modal-header mx-1 border-0">
    <input #search class="form-control form-control-lg" placeholder="Search..."
         (input)="searchUpdated.next(search.value.toString().trim().toLocaleLowerCase())"  (keyup)="handleEnter($event)"/>
  </div>

  <div slot="body">
    <i *ngIf="displayResults" class="position-relative fs-4">
      <span *ngIf="loading" class="spinner-border text-primary spinner-border-sm" aria-hidden="true"></span>
      <span *ngIf="!loading && showMinLength">Please type at least 3 characters.</span>
      <span *ngIf="!loading && !showMinLength && !searchResults?.total">We found no Results for <strong>{{search.value.toString()}}</strong>.</span>
    </i>

    <ng-container *ngIf="!loading && displayResults">

      <div *ngIf="searchResults?.centers.preview.length > 0 || searchResults?.countries.preview.length > 0" class="row mb-3">
        <strong class="mb-2">
          Centers <span *ngIf="searchResults?.countries.preview.length > 0" class="me-2">& Countries</span>
          <ng-container *ngTemplateOutlet="moreLink;context:{ref: 'centers', count: searchResults?.centers.totalCount - searchResults?.centers.preview.length}"></ng-container>
        </strong>
        <ng-container *ngFor="let country of searchResults.countries.preview">
          <ng-container *ngTemplateOutlet="searchResult;context:{
          hit: {
            document: {
                route: routingHelper.getCountryRouteByCode(country.code),
                title: country.name
              },
              highlight: {
              content: {snippet: 'A country'}
            }
          }}"></ng-container>
        </ng-container>


        <ng-container *ngFor="let center of searchResults.centers.preview">

          <ng-container *ngTemplateOutlet="searchResult;context:{
            hit: searchService.centerToSearchHit(center)
          }"></ng-container>
        </ng-container>
      </div>

      <div *ngIf="searchResults?.members.preview.length > 0" class="row mb-3">
        <strong class="mb-2">
          People
          <ng-container *ngTemplateOutlet="moreLink;context:{ref: 'people', count: searchResults?.members.totalCount - searchResults?.members.preview.length}"></ng-container>
        </strong>

        <ng-container *ngFor="let member of searchResults.members.preview">
          <ng-container *ngTemplateOutlet="searchResult;context:{
            hit: searchService.memberToSearchHit(member)
          }"></ng-container>
        </ng-container>

      </div>

      <div *ngIf="searchResults?.content?.found > 0" class="row mb-3">
        <strong class="mb-2">
          Everything Else
          <ng-container *ngTemplateOutlet="moreLink;context:{ref: 'other', count: parentFacets?.length ? searchResults?.content.found : searchResults?.content.found - searchResults.content?.hits.length}"></ng-container>
        </strong>

        <ng-container *ngIf="parentFacets?.length">
          <ng-container *ngFor="let parentFacet of parentFacets">
            <ng-container *ngTemplateOutlet="searchResult;context:{
              hit: searchService.parentFacetToSearchHit(parentFacet, searchUpdated.value)
            }"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!parentFacets?.length">
          <ng-container *ngFor="let hit of searchResults.content?.hits">
            <ng-container *ngTemplateOutlet="searchResult;context:{
              hit: hit
            }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

</app-modal-frame>

<ng-template #searchResult let-hit=hit>
  <div class="col-6 mb-3">
    <a class="d-flex align-items-center cursor-pointer" [routerLink]="hit.document.route" [queryParams]="hit.document.queryParams">

      <span class="members-list__member me-2">
        <img [src]="(hit.document.cover?.src ? imageProxy.getImageProxyUrl(hit.document.cover?.src, 120, 120, false, null) : '/assets/imgs/nophoto_center.png')" class="members-list__member-image d-block"/>
      </span>

      <span class="members-list__content">
        <span class="d-block h5 mb-0">
          <app-highlighted-string *ngIf="!hit.highlight?.title?.snippet" string="{{hit.document.title}}" [toHighlight]="search.value.toString()"/>
          <span *ngIf="hit.highlight?.title?.snippet" [innerHTML]="hit.highlight?.title?.snippet"></span>
        </span>
        <span *ngIf="hit.highlight?.content?.snippet" class="text-gray" [innerHTML]="hit.highlight?.content?.snippet"></span>
      </span>
    </a>
  </div>
</ng-template>

<ng-template #moreLink let-count="count" let-ref="ref">
    <a *ngIf="count > 0" routerLink="/search" [queryParams]="{q: searchUpdated.value, ref: ref}"  class="text-primary">See {{count}} more <i class="fa-solid fa-chevron-right ms-1"></i></a>
</ng-template>
